body {
    font-family: system, sans-serif;
}

.game-background,
.main-menu {
    padding: 0;
    margin: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100vh;
}

.controls-container {
    position: absolute;
    right: 200px;
    top: 235px;
    display: flex;
    /*align-items: center;*/
}


.controls-container .start-player1,
.controls-container .start-player2 {
    background: #fff;
    width: 256px;
    height: 256px;
    margin: 10px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 2em;
}

.controls-container .start-player1 img,
.controls-container .start-player2 img {
    width: 150px;
    height: 150px;
    filter: drop-shadow( 0px 2px 3px rgba(0,0,0,.4) );
}

.game-sidebar {
    min-width: 400px;
    height: calc(100vh);
    position: absolute;
    left: 0px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
}

.game-sidebar button {
    width: 290px;
    height: 80px;
    background: #fff;
    border: 0;
    font-size: 2em;
}

.game-sidebar .sidebar-bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 220px;
    border-radius: 100%;
    background: rgba(255, 255, 255, .8);
    font-size: 2em;
    padding: 0;
    margin: 0;
}

.card-image {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: filter 0.5s;
    filter: drop-shadow( 2px 2px 10px rgba(0,0,0,.5) );
}

.main-menu {
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    background-color: grey;
}

.card-grid {
    position: absolute;
    width: calc(100vw - 450px);
    height: calc(100vh - 50px);
    left: 400px;
    top: 25px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.player-bar {
    position: absolute;
    --width: 600px;
    width: var(--width);
    height: 80px;
    bottom: 0px;
    left: calc(200px + (100vw - var(--width)) / 2);
    display: flex;
    justify-content: stretch;
    align-items: center;
}

.player-bar .player-1,
.player-bar .player-2 {
    background: #fff;
    padding: 15px 20px; 
    font-size: 2.5em;
    width: 300px;
}

.player-bar .player-1.active,
.player-bar .player-2.active {
    background: red;
    color: #fff;
}

.card-container {
    width: 150px;
    box-sizing: content-box;
    display: flex;
    flex: 1 1 25%;
    flex-wrap: wrap;
}

.won-modal {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    filter: blur(3px);
    animation-duration: 1.5s;
    animation-name: blur-it;
}

@keyframes blur-it {
    from {
        filter: blur(0px);
    }

    to {
        filter: blur(3px);
    }
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: transparent;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card.active .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) 
  .flip-card-front { }*/
  
  /* Style the back side */
  .flip-card-back {
    transform: rotateY(180deg);
  }

  .won-stats {
      z-index: 9999;
      position: absolute;
      top: 17px;
      left: 253px;
      width: 650px;
      height: 720px;
      background-color: rgba(255,255,255,0.95);
      box-shadow: 0px 2px 10px rgba(0,0,0,.4);
      color: black;
      font-size: 40px;
      text-align: center;
  }

  .matched {
      filter: drop-shadow(0 0 16px green);
  }

  .lang-container {
    position: fixed;
    bottom: 0;
    left: calc(50% - 205px);
  }

  .lang-btn,
  .won-stats button {
      width: 200px;
      height: 70px;
      background: #fff;
      font-size: 1.3em;
      margin-bottom: 10px;
      border: 1px solid #ccc;
  }

  .won-stats button {
      width: auto;
      height: 80px;
      background: #333;
      font-size: 1.3em;
      margin-bottom: 10px;
      color: #fff;
      padding: 5px 50px 10px;
      margin-top: 50px;
  }

  .lang-btn:first-child {
      margin-right: 10px;
  }

  .players-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .players-container p {
    margin-block-start: .3em;
    margin-block-end: .3em;
  }

  .players-container p:first-child {
      text-decoration: underline;
  }

.new.highscore {
    font-weight: bold;
}


.help-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* cards thing is 552px x 420px */
    width: 532px;
    height: 256px;
    background-color: white;
    filter: drop-shadow( 0px 2px 3px rgba(0,0,0,.4) );
    font-size: 22px;
}
.help-menu {
    position: absolute;
    right: 210px;
    top: 520px;
}

.help-box-button {
    display: inline-block;
    margin-right: 16px;
}

.help-button, .help-box-button {
    background-color: black;
    color: white;
    width: 32px;
    height: 32px;
    font-size: 28px;
    text-align: center;
    border-radius: 100%;
}

.help-box-title {
    height: 48px;
    width: 100%;
    font-size: 32px;
    justify-content: center;
    text-align: center;
    margin-bottom: 12px;
}

.help-box-content {
    margin-left: 28px;
    margin-right: 28px;
}

.help-box-close {
    width: 32px;
    height: 32px;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 12px;
    left: 12px;
}

.help-game-box {
    position: absolute;
    bottom: 24px;
    right: 24px;
    transition: 250ms transform;
    transform-origin: 100% 100%;
    transform: scale(1);
}

.help-game-box-invisible {
    transform: scale(0);
}

.help-game-button {
    position: absolute;
    bottom: 32px;
    right: 32px;
}

.help-game-button.help-button {
    width: 64px;
    height: 64px;
    font-size: 56px;
}
